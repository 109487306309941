import React from "react";
import webDew from "../documents/webDev.pdf";

const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Обо мне</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Коротко о главном
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp" style={{ textIndent: "25px" }}>
            <h2 className="text-8 fw-400 mb-3">
              <span className="fw-700 border-bottom border-3 border-primary">
                Родионов Илья
              </span>
            </h2>
            <p className="text-4">
              Я Frontend-разработчик. Мне нравится создавать решения,
              которые приносят реальную пользу. Работаю JavaScript-разработчиком в BTLZ, оптимизирую процессы бизнеса на основе платформы Google (Google Sheets, BigQuery).

            </p>
            <p className="text-4">
              Долгое время занимался разработкой как хобби – решал задачи на <a href='https://www.codewars.com/users/Rodionov84' target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>codewars</a>, проходил курсы - я выпускник Netology - модуль <a href={webDew} target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>Web developer</a>. Помогал коллегам
              по офису, избавляя их от рутинных задач, с помощью нескольких строчек кода.
            </p>
            <p className="text-4">
              Помимо разработки веб-приложений увлекаюсь спортом (в основном единоборствами – кудо, муай тай,
              самбо). В свободное время люблю путешествовать, играю на гитаре и фортепиано.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-25 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat fw-600" data-wow-delay="1.3s">
                  JS
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                <span className="fw-700">React & Redux</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
