import React from "react";
import resumeFile from "../documents/resume.pdf";
import phpSQL from "../documents/phpSQL.pdf";
import webDew from "../documents/webDev.pdf";
import jurisprudence from "../documents/jurisprudence.pdf";
import {
  ReactIcon, JavaScriptIcon, HTMLIcon, CSSIcon, BigQueryIcon,
  ReduxIcon, PostgreSQLICON, NodeIcon, MySQLIcon,
  ExcelIcon, GitIcon, BootstrapIcon, WordpressIcon,
  GoogleSheetIcon, PhpIcon, SassIcon, WebPackIcon, AppsScriptIcon,
} from "../logo-skills.js";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2002 - 2007",
      title: "Московский Институт Права",
      place: "Москва",
      desc: "Квалификация Юрист, специальность \"Юриспруденция\" ",
      url: jurisprudence,
    },
    {
      yearRange: "2018 - 2019",
      title: "Netology",
      place: "Москва",
      desc: "Профессия веб-разработчик",
      url: webDew,
    },
    {
      yearRange: "2017 - 2018",
      title: "Netology",
      place: "Москва",
      desc: "PHP/SQL: back-end разработка и базы данных",
      url: phpSQL,
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2023",
      title: "JavaScript-разработчик",
      place: "BTLZ",
      desc: [
        "- Автоматизация процессов бизнеса на базе платформы Google (Google Sheets, BigQuery).",
        "- Создание CRM, интеграция таблиц между собой и с другими сервисами.",
        "- Настройка автоматических уведомлений в WhatsApp, Telegram и Email.",
        "- Написание скриптов и макросов",
      ],
    },
    {
      yearRange: "2020 - 2023",
      title: "Главный эксперт Отдел аудита",
      place: "ОАО СОГАЗ",
      desc: [
        "- Анализ данных, выгрузка информации и её структурирование.",
        "- Выявление особенностей взаимодействия между отделами Страховой компании.",
        "- Контроль деятельности подразделений головного офиса, филиалов, независимых экспертиз и иных партнёров Страховой компании (СТОА, агенты и пр.).",
      ],
    },
    // {
    //   yearRange: "2019 - 2020",
    //   title: "Главный эксперт Отдел урегулирования убытков по ОСАГО",
    //   place: "ОАО СОГАЗ",
    //   desc: [
    //     "- Рассмотрение досудебных претензий.",
    //     "- Урегулирование убытков автострахования: Признание случая страховым, Согласование выдачи направления на ремонт, Проверка калькуляций независимой экспертизы, Проверка комплекта документов.",
    //     "- Согласование страховых актов, расчёт неустоек."],
    // },
    // {
    //   yearRange: "2009 - 2018",
    //   title: "Экспер оценщик",
    //   place: "ООО \"НЭК-ГРУП\"",
    //   desc: "Определение ущерба транспортным средствам. Составление отчётов для суда. Расчёт износа транспортного средства, составление калькуляции.",
    // },

  ];

  const skills = [
    {
      name: "HTML",
      icon: <HTMLIcon />,
    },
    {
      name: "CSS",
      icon: <CSSIcon />,
    },
    {
      name: "Sass",
      icon: <SassIcon />,
    },
    {
      name: "JavaScript",
      icon: <JavaScriptIcon />,
    },
    {
      name: "React JS",
      icon: <ReactIcon />,
    },
    {
      name: "Redux",
      icon: <ReduxIcon />,
    },
    {
      name: "Node.JS",
      icon: <NodeIcon />,
    },
    {
      name: "PHP",
      icon: <PhpIcon />,
    },
    {
      name: "PostgreSQL",
      icon: <PostgreSQLICON />,
    },
    {
      name: "WebPack",
      icon: <WebPackIcon />,
    },
    {
      name: "MySQL",
      icon: <MySQLIcon />,
    },
    {
      name: "BigQuery",
      icon: <BigQueryIcon />,
    },
    {
      name: "AppsScript",
      icon: <AppsScriptIcon />,
    },
    {
      name: "G-Sheets",
      icon: <GoogleSheetIcon />,
    },
    {
      name: "Excel",
      icon: <ExcelIcon />,
    },
    {
      name: "Bootstrap",
      icon: <BootstrapIcon />,
    },
    {
      name: "Wordpress",
      icon: <WordpressIcon />,
    },
    {
      name: "Git",
      icon: <GitIcon />,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Резюме</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Опыт и образование
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">ВУЗ и курсы повышения квалификации</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    {value.url
                      ?
                      <p className="text-muted">
                        <a href={value.url} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>
                          {value.desc}
                        </a>
                      </p>
                      : <p className="text-muted">{value.desc}</p>
                    }
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Опыт работы</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    {
                      Array.isArray(value.desc)
                        ? <ul className="text-muted" style={{ listStyleType: "none", marginLeft: "0", paddingLeft: "0" }}>
                          {value.desc.map(str => <li className="text-muted">{str}</li>)}
                        </ul>
                        : <p className="text-muted">
                          {value.desc}
                        </p>
                    }
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">Навыки и владение технологиями</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-2 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2 wow fadeInUp">
                  <div style={{ display: "inline-block", marginRight: "5px" }} title={skill.name}>
                    {skill.icon}
                  </div>

                  <span>{skill.name}</span>
                </p>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Скачать резюме
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
